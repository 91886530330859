<template>
  <div class="mobile-home-wrapper">
    <div class="mobile-home">
      <div class="top">
        <el-image class="logo" fit="fill" :src="logoImage" alt=""></el-image>
        <el-image class="company" fit="fill" :src="companyImage" alt=""></el-image>
      </div>
      <el-image class="content" fit="fill" :src="contentImage" alt=""></el-image>
      <div class="to-pc">
        <div class="title">
          <span style="fontWeight: 500; fontSize: 18px">敬请移步至PC端浏览</span>
        </div>
        <div class="desc">或打开浏览器搜索<span style="fontWeight: 500">「云和木玩大赛」</span>找到我们</div>
      </div>
      <el-image class="footer" fit="fill" :src="footerImage" alt=""></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobileHome',
  data() {
    return {
      logoImage: require('@/static/images/mobile/logo.png'),
      companyImage: require('@/static/images/mobile/company.png'),
      contentImage: require('@/static/images/mobile/content.jpg'),
      footerImage: require('@/static/images/mobile/footer.png')
    };
  },
  components: {},
  watch: {},
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="less">
.mobile-home-wrapper {
  padding: 3vw;
  .mobile-home {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        width: 55.6vw;
      }
      .company {
        width: 21.5vw;
      }
    }
    .content {
      height: 120vw;
      margin: 2.13vw 0;
    }
    .to-pc {
      padding: 2vw 0;
      border-radius: 4px;
      background-color: #d8d8d8;
      color: #515151;
      font-size: 14px;
    }
    .footer {
      height: 23.7vw;
      margin-top: 3vw;
    }
  }
}
</style>
